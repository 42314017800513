import { lazy } from "react";
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { lazyRetry } from "./utils/lazyRetry";
const LandingPage = lazy(() => lazyRetry(() => import("./pages/LandingPage")));
export const LandingPageRoutes = () => {
  return [
    { path: "/", element: <LandingPage /> },
    // { path: "/nft", element: <NFTPage /> },
    // { path: "/profile", element: <ProfilePage /> },
    // {
    //   path: "/campaigns",
    //   element: <CampaignPage />,
    // },
    // { path: "/campaigns/:id", element: <EarnPage /> },
    { path: "*", element: <Navigate to="/" /> },
  ];
};
const Routes = () => {
  let routes: RouteObject[] = LandingPageRoutes();
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};
export default Routes;
