import * as authApi from "@/api/auth.service";
import { ENVS } from "@/configs/Configs.env";
import useAddress from "@/hooks/useAddress";
import useFetchProfile from "@/hooks/useFetchProfile";
import { useLogin, useLogout, useProfile } from "@/store/profileStore";
import { getSignature } from "@/utils/solana";
import SolanaRpc from "@/utils/solanaRPC";
import { mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import {
  CHAIN_NAMESPACES,
  IProvider,
  OPENLOGIN_NETWORK_TYPE,
  WALLET_ADAPTERS,
} from "@web3auth/base";
import { getDefaultExternalAdapters } from "@web3auth/default-solana-adapter";
import { Web3Auth } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export const umi = createUmi(ENVS.REACT_APP_SOL_RPC, {
  commitment: "confirmed",
}).use(mplCandyMachine());

const WEB3AUTH_CLIENT_ID = ENVS.REACT_APP_WEB3_AUTH_CLIENT_ID;
// const WEB3AUTH_CLIENT_ID =
//   "BEglQSgt4cUWcj6SKRdu5QkOXTsePmMcusG5EAoyjyOYKlVRjIF1iCNnMOTfpzCiunHRrMui8TIwQPXdkQ8Yxuk";

export enum SocialLogin {
  GOOGLE = "google",
  TWITTER = "twitter",
  APPLE = "apple",
}
export enum WalletLogin {
  PHANTOM = "phantom",
  SOLFLARE = "solflare",
  BITGET = "Bitget",
}
export interface AuthProviderData {
  web3auth: Web3Auth | null;
  provider: IProvider | null;
  // onSuccessfulLogin: (data: CONNECTED_EVENT_DATA, user: any) => void;
  login: () => void;
  logout: () => void;
  loggedIn: boolean;
  isVerifyAccount: boolean;
}

export const useWeb3Auth = () => {
  const context = useContext(Web3AuthContext);
  if (!context) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};

export const Web3AuthContext = React.createContext<AuthProviderData>({
  web3auth: null,
  provider: null,
  // onSuccessfulLogin: (data: any) => {},
  login: () => {},
  logout: () => {},
  loggedIn: false,
  isVerifyAccount: false,
});

export const Web3AuthProvider = ({ children }: { children: ReactNode }) => {
  const [provider, setProvider] = useState<IProvider | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
  const { accessToken } = useProfile();
  const loginProfile = useLogin();
  const logoutProfile = useLogout();
  const { getMe } = useFetchProfile();
  const { address } = useAddress();
  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    chainId: ENVS.REACT_APP_WEB3_AUTH_CHAIN_ID, // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
    displayName: ENVS.REACT_APP_WEB3_AUTH_NETWORK,
    blockExplorer: "https://explorer.solana.com",
    ticker: "SOL",
    tickerName: "Solana Token",
    rpcTarget: ENVS.REACT_APP_SOL_RPC, // This is the public RPC we have added, please pass on your own endpoint while creating an app
  };

  useEffect(() => {
    const init = async () => {
      try {
        const privateKeyProvider = new SolanaPrivateKeyProvider({
          config: { chainConfig },
        });
        const web3authClient = new Web3Auth({
          clientId: WEB3AUTH_CLIENT_ID,
          // uiConfig refers to the whitelabeling options, which is available only on Growth Plan and above
          // Please remove this parameter if you're on the Base Plan
          uiConfig: {
            appName: "W3A Heroes",
            mode: "dark",
            loginMethodsOrder: ["google", "apple", "twitter"],
            // logoLight: "https://web3auth.io/images/web3authlog.png",
            // logoDark: "https://web3auth.io/images/web3authlogodark.png",
            defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
            loginGridCol: 3,
            primaryButton: "externalLogin", // "externalLogin" | "socialLogin" | "emailLogin"
            uxMode: "redirect",
          },
          web3AuthNetwork:
            ENVS.REACT_APP_WEB3_AUTH_NETWORK as OPENLOGIN_NETWORK_TYPE,
          privateKeyProvider: privateKeyProvider,
        });

        const adapters = await getDefaultExternalAdapters({
          options: {
            clientId: WEB3AUTH_CLIENT_ID,
            chainConfig,
          },
        });
        adapters.forEach((adapter) => {
          web3authClient.configureAdapter(adapter);
        });

        const openloginAdapter = new OpenloginAdapter({
          // loginSettings: {
          //   mfaLevel: "optional",
          // },
          adapterSettings: {
            whiteLabel: {
              appName: "W3A Heroes",
              // logoLight: "https://web3auth.io/images/web3authlog.png",
              // logoDark: "https://web3auth.io/images/web3authlogodark.png",
              defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
              mode: "light", // whether to enable dark mode. defaultValue: false
            },
          },
        });

        web3authClient.configureAdapter(openloginAdapter);

        setWeb3auth(web3authClient);

        await web3authClient.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: "openlogin",
              loginMethods: {
                // Disable facebook and reddit
                reddit: {
                  name: "reddit",
                  showOnModal: false,
                },
                discord: {
                  name: "discord",
                  showOnModal: false,
                },

                email_passwordless: {
                  name: "email_passwordless",
                  showOnModal: true,
                },
                sms_passwordless: {
                  name: "sms_passwordless",
                  showOnModal: false,
                },
              },
            },
          },
        });
        setProvider(web3authClient.provider);
        console.log("web3authClient.connected: ", web3authClient.connected);
        if (web3authClient.connected) {
          setLoggedIn(true);
        }
      } catch (error) {
        console.log("🚀 ~ file: AuthProvider.tsx:84 ~ init ~ error:", error);
        // dispatch(logoutAsync());
      }
    };

    // init();
  }, []);

  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    try {
      const web3authProvider = await web3auth.connect();
      setProvider(web3authProvider);
      setLoggedIn(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new SolanaRpc(provider);
    const balance = await rpc.getBalance();
    console.log("🚀 ~ getBalance ~ balance:", balance);
  };

  const getUserInfo = async () => {
    if (!web3auth) {
      return;
    }
    const user = await web3auth.getUserInfo();
    return user;
  };
  const handleLoginEffect = useCallback(async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    try {
      const rpc = new SolanaRpc(provider);
      const accounts = await rpc.getAccounts();
      const { nonce, publicAddress, signed } = await authApi.requestNonce(
        accounts[0]
      );
      const infoUser = await getUserInfo();
      const signature: string = await getSignature(
        publicAddress,
        nonce,
        provider
      );
      const { token } = await authApi.login(
        publicAddress,
        signature,
        nonce,
        infoUser || {}
      );
      if (token) {
        window.token = token;
        console.log("🚀 ~ handleLoginEffect ~ window.token:", window.token);
        const profile: any = await authApi.getProfile();
        console.log({
          accessToken: token,
          user: profile?.user,
          signed: signed,
          balance: profile?.balance,
        });
        loginProfile({
          accessToken: token,
          user: profile?.user,
          balance: profile?.balance,
        });
      }
    } catch (error) {
      console.log("🚀 ~ handleLoginEffect ~ error:", error);
    }
  }, [provider]);

  const reLoginProfile = async () => {
    try {
      await getMe();
    } catch (error) {
      logout();
      console.log("🚀 ~ reLoginProfile ~ error:", error);
    }
  };

  useEffect(() => {
    if (loggedIn && !accessToken) {
      handleLoginEffect();
    }
    if (loggedIn && accessToken) {
      (window as any).token = accessToken;
      reLoginProfile();
    }
  }, [loggedIn, accessToken, handleLoginEffect]);

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    logoutProfile();
    await web3auth.logout();
    setProvider(null);
    setLoggedIn(false);
  };
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (provider && address) {
  //         const solana = new SolanaRpc(provider);
  //         const privateKey = await solana.getPrivateKey();
  //         if (privateKey) {
  //           localStorage.setItem(PRIVATE_KEY_B58, privateKey);
  //         }
  //       }
  //       //  else {
  //       //   localStorage.removeItem(PRIVATE_KEY_B58);
  //       // }
  //     } catch (error) {
  //       console.log("🚀 ~ error:", error);
  //     }
  //   })();
  // }, [provider, address]);
  const ctx: AuthProviderData = {
    web3auth,
    provider,
    login,
    logout,
    loggedIn: loggedIn && !!address,
    isVerifyAccount: !!accessToken && loggedIn,
  };
  return (
    <Web3AuthContext.Provider value={ctx}>{children}</Web3AuthContext.Provider>
  );
};

// export const AuthConsumer = AuthContext.Consumer;
