import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface IUser {
  frens: number;
  id: number;
  incomeEarned: number;
  invitedBy: string;
  publicAddress: string;
  publicNFTAddress: string;
  publicTokenAddress: string;
  referralCode: string;
}

export interface IBalanceProfile {
  Address: string;
  balance: number;
  id: number;
  interest: number;
  itemId: number;
  name: string;
  status: number;
  symbol: string;
}

export interface ProfileState {
  user: IUser;
  balance: IBalanceProfile[];
  accessToken: string;
}

export interface UserActions {
  login: (user: ProfileState) => void;
  logout: () => void;
}

export type TUserStore = ProfileState & UserActions;

const defaultUser = {
  id: 0,
  publicAddress: "",
  publicTokenAddress: "",
  publicNFTAddress: "",
  referralCode: "",
  invitedBy: "",
  incomeEarned: 0,
  frens: 0,
};
const logoutUser = (draft: ProfileState) => {
  draft.user = { ...defaultUser };
  draft.balance = [];
  draft.accessToken = "";
};

const handleLogin = (draft: TUserStore, data: ProfileState) => {
  draft.user = data.user;
  draft.accessToken = data.accessToken;
  draft.balance = data.balance;
};

const profileStore = create(
  persist(
    immer<TUserStore>((set) => ({
      user: { ...defaultUser },
      balance: [],
      accessToken: "",
      login: (data: ProfileState) => set((draft) => handleLogin(draft, data)),
      logout: () => set((draft) => logoutUser(draft)),
    })),
    {
      name: "profile-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useLogin = () => profileStore((state) => state.login);
export const useLogout = () => profileStore((state) => state.logout);
export const useProfile = () =>
  profileStore((state) => ({
    user: state.user,
    balance: state.balance,
    accessToken: state.accessToken,
  }));
