import * as authApi from "@/api/auth.service";
import { useLogin, useProfile } from "@/store/profileStore";

export default function useFetchProfile() {
  const loginProfile = useLogin();
  const { accessToken } = useProfile();

  const getMe = async () => {
    const profile: any = await authApi.getProfile();
    loginProfile({
      accessToken: accessToken,
      user: profile?.user,
      balance: profile?.balance,
    });
  };

  return { getMe };
}
